import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ data protection policy.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview & Purpose`}</h2>
    <p>{`This Data Protection Policy refers to our commitment to treat information of employees, customers, stakeholders and other interested parties with the utmost care and confidentiality.  With this policy, we ensure that we gather, store and handle data fairly, transparently and with respect towards individual rights.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy refers to all parties (employees, job candidates, customers, suppliers etc.) who provide any amount of information to us.`}</p>
    <h3>{`Who is covered under the Data Protection Policy?`}</h3>
    <p>{`Employees of Data Migrators and its subsidiaries must follow this policy. Contractors, consultants, partners and any other external entity are also covered. Generally, our policy refers to anyone we collaborate with or acts on our behalf and may need occasional access to data.`}</p>
    <h2>{`Policy elements`}</h2>
    <p>{`As part of our operations, we need to obtain and process information. This information includes any offline or online data that makes a person identifiable such as names, addresses, usernames and passwords, digital footprints, photographs, social security numbers, financial data etc.  Data Migrators collects this information in a transparent way and only with the full cooperation and knowledge of interested parties. Once this information is available to us, the following rules apply.`}</p>
    <p>{`Our data will be:`}</p>
    <ul>
      <li parentName="ul">{`Accurate and kept up-to-date`}</li>
      <li parentName="ul">{`Collected fairly and for lawful purposes only`}</li>
      <li parentName="ul">{`Processed by Data Migrators within its legal and moral boundaries`}</li>
      <li parentName="ul">{`Protected against any unauthorized or illegal access by internal or external parties`}</li>
    </ul>
    <p>{`Our data will not be:`}</p>
    <ul>
      <li parentName="ul">{`Communicated informally`}</li>
      <li parentName="ul">{`Stored for more than a specified amount of time`}</li>
      <li parentName="ul">{`Transferred to organizations, states or countries that do not have adequate data protection policies`}</li>
      <li parentName="ul">{`Distributed to any party other than the ones agreed upon by the data’s owner (exempting legitimate requests from law enforcement authorities)`}</li>
    </ul>
    <p>{`In addition to ways of handling the data Data Migrators has direct obligations towards people to whom the data belongs. Specifically we must:`}</p>
    <ul>
      <li parentName="ul">{`Let people know which of their data is collected`}</li>
      <li parentName="ul">{`Inform people about how we’ll process their data`}</li>
      <li parentName="ul">{`Inform people about who has access to their information`}</li>
      <li parentName="ul">{`Have provisions in cases of lost, corrupted or compromised data`}</li>
      <li parentName="ul">{`Allow people to request that we modify, erase, reduce or correct data contained in our databases`}</li>
    </ul>
    <h2>{`Actions`}</h2>
    <p>{`To exercise data protection we’re committed to:`}</p>
    <ul>
      <li parentName="ul">{`Restrict and monitor access to sensitive data`}</li>
      <li parentName="ul">{`Develop transparent data collection procedures`}</li>
      <li parentName="ul">{`Train employees in online privacy and security measures`}</li>
      <li parentName="ul">{`Build secure networks to protect online data from cyberattacks`}</li>
      <li parentName="ul">{`Establish clear procedures for reporting privacy breaches or data misuse`}</li>
      <li parentName="ul">{`Include contract clauses or communicate statements on how we handle data`}</li>
      <li parentName="ul">{`Establish data protection practices (document shredding, secure locks, data encryption, frequent backups, access authorization etc.)`}</li>
    </ul>
    <p>{`Our data protection provisions will appear on our website.`}</p>
    <h2>{`Non-compliance`}</h2>
    <p>{`All principles described in this policy must be strictly followed. A breach of data protection guidelines will invoke disciplinary and possibly legal action.`}</p>
    <h3>{`Further Reading`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.legislation.gov.uk/ukpga/1998/29/contents"
        }}>{`Data Protection Act of 1998 (UK)`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://us.practicallaw.com/6-502-0467"
        }}>{`Data protection in United States`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      